import React from 'react'
import LoaderButton from '../LoaderButton'
import FormViewer from '../FormViewer'
import { translate } from '../../utils/useful'
// import HttpService from '../../utils/Http.services';

class LoginInfoStep extends React.Component {
    state = {
        errors: {},
        formHeaders: [
            { key: 'name', type: 'TextInput', information: { label: '{{lang}}name', icon: '', placeholder: '{{lang}}insertName', required: true } },
            { key: 'family', type: 'TextInput', information: { label: '{{lang}}family', icon: '', placeholder: '{{lang}}insertFamily', required: false } },
            { key: 'password', type: 'PasswordInput', information: { label: '{{lang}}password',hint:"کلمه عبور باید حداقل ۸ کارکتر باشد", icon: '', placeholder: '{{lang}}insertPassword', required: true } },
            { key: 'passwordConfirm', type: 'PasswordInput', information: { label: 'تکرار کلمه عبور',hint:"کلمه عبور باید حداقل ۸ کارکتر باشد", icon: '', placeholder: '{{lang}}insertPassword', required: true } }]

    }


    componentDidMount() {
        window.addEventListener("keypress", this.keyPress)

    }
    componentWillUnmount() {
        window.removeEventListener("keypress", this.keyPress)
    }

    keyPress = (e) => {
        if (e?.keyCode == 13) {
            this.postUserInfo()
        }
    }



    postUserInfo = () => {
        this.setState({  errors: {} })
        let data = this.form.getForm()
        if (data) {
            if (data.password === data.passwordConfirm) {
                this.setState({isLoading: true,})
                this.props.setData(data, () => {
                    this.props.postUserInfo((result, err) => {
                        if (err) { this.setState({ errors: err }) }
                        this.setState({ isLoading: false })
                    })
                })
            }else{
                this.setState({ errors: 'کلمه عبور با تکرار آن یکسان نیست' })  
            }
        }
    }


    render() {
        return (

            <div className="flexcc flex-column">
                <h2 className="mt-4 mb-0" style={{ fontSize: 27 }}>{translate("information")}</h2>
                <p className="mb-4 mt-0" style={{ fontSize: 15 }}>{translate('insert-information')}</p>

                <div className="w-100" style={{ maxWidth: 400 }}>

                    <FormViewer ref={el => this.form = el} headers={this.state.formHeaders} initData={this.props.initData} errors={this.state.errors} inputClass={'modern-input'} />

                    <div className="text-center">
                        <LoaderButton
                            onClick={this.postUserInfo}
                            isLoading={this.state.isLoading}
                            text={translate("signup").toUpperCase()}
                            type={"Oval"}
                            className="mt-4 mb-4"
                            buttonStyle={{ outline: 'none', backgroundColor: '#FF6C00', cursor: 'pointer', padding: '10px 50px', borderRadius: 30, fontSize: 15, color: '#fff', border: 'none' }}
                            width={40}
                            height={40}
                            color={'#202020'}
                        />
                    </div>

                    <div className="w-100 text-center">
                        <button className="mb-3" onClick={() => this.props.lastStage()} style={{ fontSize: 13, }}>{translate('back-one-stage')}</button>
                    </div>

                </div>
            </div>

        )
    }
}

export default LoginInfoStep;