import React from "react";
import Dropzone from 'react-dropzone'
import Cropper from 'react-cropper';
import Modal from "../Modal.jsx";
import { siteConfig } from '../../variables/config'
import 'cropperjs/dist/cropper.css'
import { checkTextTranslation, imageAddress } from "../../utils/useful.js";

class ImageInput extends React.Component {

    state = {
        images: [],
        files: [],
        oldImages: null
    }

    componentDidMount() {
        console.log(this.props.data)
        if (this.props.data) {
            let data = JSON.parse(JSON.stringify(this.props.data))
            this.setState({ oldImages: Array.isArray(data) ? data : [data] })
        } else {
            this.setState({ oldImages: null })
        }
        // console.log(this.props.files)
        if (this.props.files) {
            let files = Array.isArray(this.props.files) ? [...this.props.files] : [this.props.files]

            //files = JSON.parse(JSON.stringify(files))
            files.forEach((file, index) => {
                // console.log(file)
                files[index] = URL.createObjectURL(file)
            });
            this.setState({ images: files })
        }

    }

    componentDidUpdate(preProps) {
        if (preProps.data != this.props.data) {
            let data = JSON.parse(JSON.stringify(this.props.data))
            console.log(data)
            if (data && data != '' && data != []) {
                this.setState({ oldFiles: Array.isArray(data) ? data : [data] })
            } else {
                this.setState({ oldFiles: [] })
            }
        }

        // if (this.props.files && preProps.files != this.props.files ) {
        //     this.setState({ images: Array.isArray(this.props.files) ? this.props.files : [this.props.files] })
        // }
        // console.log(preProps)
    }


    // componentWillReceiveProps(newProps) {
    //     if (newProps.data !== this.props.data){

    //     }

    //         // console.log(newProps)
    // }

    conditionalSettings(param, condition) {
        if (this.props.settings) {
            if (this.props.settings[param] === condition) {
                return true
            }
        }
        return false
    }


    showImage(imageAddress) {
        this.setState({ selectedImage: imageAddress })
        this.imageModal.showModal()
    }

    render() {
        const dropzoneRef = React.createRef();
        let info = this.props.header.information
        let allImages = 0

        if (this.state.oldImages?.length) {
            allImages = allImages + this.state.oldImages.length
        }

        if (this.state.images?.length) {
            allImages = allImages + this.state.images.length
        }

        // console.log(allImages)
        // console.log(allImages < info.maxLength + 1)

        // console.log((!info.maxLength || (allImages < info.maxLength + 1)))
        return (
            <div className="flexcc w-100 p-3 flex-column">


                {!(!info.maxLength || (allImages < info.maxLength)) || (this.state.images.length > 0 && info && info.single) ? (<div></div>) : (

                    <Dropzone
                        ref={dropzoneRef}
                        multiple={!info.single && !info.corpper ? true : false}
                        accept={['image/jpeg', 'image/png']}
                        onDrop={this.onDrop.bind(this)}
                        style={{ flex: 1, cursor: 'pointer' }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="text-center w-100 outline-none" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img src={'/images/image.svg'} width={60} alt="" />
                                <p className="" style={{ opacity: 1, fontSize: 13, marginTop: 3, color: '#007affdd' }}>{checkTextTranslation(info.placeHolder ? info.placeHolder : '{{lang}}choose-image')}</p>
                            </div>
                        )}
                    </Dropzone>
                )}


                <div className="p-0 m-0 mt-1" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                    {this.state.oldImages && Array.isArray(this.state.oldImages) && this.state.oldImages.map((prop, index) => {
                        return (
                            <div style={{ position: 'relative' }}>
                                <img onClick={() => this.showImage(siteConfig.assetsDomain + prop)} className={"m-1 mt-3"} src={imageAddress(prop, null, 'small')} height="100px" style={{ borderRadius: 4 }} alt="" />
                                <div onClick={() => { this.removeOldFile(index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )
                    })}


                    {this.state.images.map((prop, index) => {
                        return (
                            <div key={index} style={{ position: 'relative' }}>
                                <img onClick={() => this.showImage(prop)} className={"m-1 mt-3"} src={prop} height="100px" style={{ borderRadius: 4 }} alt="" />
                                <div onClick={() => { this.removeFile("key", index) }} style={{ position: 'absolute', top: 5, left: 0, backgroundColor: '#ee5050dd', borderRadius: '50%', width: 25, height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 0, cursor: 'pointer' }}><p style={{ color: '#fff', marginTop: 4 }}>X</p></div>
                            </div>
                        )
                    })}
                </div>


                <p className="mt-2 text-smaller" style={{color:'#ee5050'}}>{this.state.error}</p>


                <Modal className="lightiransansfont" ref={el => this.croperModal = el} maxWidth={700} style={{}}>

                    <div>
                        <Cropper
                            ref={el => this.cropper = el}
                            src={this.state.image}
                            style={{ maxHeight: 400, }}
                            aspectRatio={info.ratio ? (info.ratio.split(':')[0] / info.ratio.split(':')[1]) : null}
                            // guides={false}
                            zoomable={false}
                            background={false}
                            guides={true}
                            viewMode={3}
                            // zoomable={true}
                        // fillColor={'#fff'}
                        />

                        <div className=" ml-auto mr-auto  mt-3 mb-4" style={{ textAlign: 'center', marginBottom: 0 }}>
                            <button onClick={() => { this.croperModal.hideModal(); this._crop(); }} style={{ borderRadius: 30, fontSize: 15, padding: '8px 15px', backgroundColor: '#202020', color: '#fff' }}>افزودن تصویر</button>
                        </div>
                    </div>
                </Modal>



                <Modal ref={el => this.imageModal = el} maxWidth={500}>
                    <img src={this.state.selectedImage} style={{ width: '100%' }} alt="" />
                </Modal>



            </div>
        );
    }


    onDrop(filesInput) {
        // console.log(filesInput)
        this.setState({ error: null })

        let info = this.props.header.information

        // console.log(filesInput)

        let isAllowed = true

        if (info.maxSize) {
            filesInput.forEach(file => {
                if (file.size > info.maxSize * 1000) {
                    isAllowed = false
                }
            });
        }

        if (isAllowed) {
            const newImage = URL.createObjectURL(filesInput[0])
            // console.log(newImage)
            // document.getElementById("myImage").src = newImage
            // document.getElementById("mySource").src = newImage
            // this.setState({ showVideo: false })
            this.setState({ image: newImage, type: filesInput[0].type })

            if (this.props.header.information.cropper) {
                this.croperModal.showModal()
            } else {

                var images = this.state.images
                var files = this.state.files
                // var dataURL = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 1)
                images.push(newImage)
                // files.push(this.blobToFile(this.dataURLtoFile(dataURL),'a.jpeg'))
                // var file = this.dataURLtoFile(dataURL, 'a.jpg');
                files.push(filesInput[0])
                this.setState({ images, files })
                this.props.changeFiles(this.props.header.information.single ? files[0] : files, this.props.header.key, 'file')

            }
        } else {
            this.setState({ error: 'حجم فایل انتخابی بیش از حد مجاز است' })
        }

        // this.setState({ showVideo: true })
        // this.setState({ file: files[0] })
    }


    urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    dataURLtoFile(dataurl, filename) {
        // var mime = 'image/jpeg';
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        // this.setState({testBlob:bstr})

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }


        var ia = new Uint8Array(bstr.length);
        for (var i = 0; i < bstr.length; i++) {
            ia[i] = bstr.charCodeAt(i);
        }
        // return new File([u8arr], filename, {type:mime});
        // return new Blob([u8arr], {type:mime});
        return new Blob([ia], { type: mime });
    }

    blobToFile = (blob, fileName) => {
        // var b: any = theBlob;
        var myBlob = blob
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        myBlob.lastModifiedDate = new Date();
        myBlob.name = fileName;

        //Cast to a File() type
        return new File(myBlob);
    }


    _crop() {
        // image in dataUrl
        var images = this.state.images
        var files = this.state.files
        // console.log(this.state.image)
        let options = { imageSmoothingQuality: 'medium' }
        if (this.state.type != 'image/png') {
            options.fillColor = '#fff'
        }
        // return
        let dataURL = this.cropper.getCroppedCanvas(options).toDataURL(this.state.type, 1)
        images.push(dataURL)
        // files.push(this.blobToFile(this.dataURLtoFile(dataURL),'a.jpeg'))
        let file = this.dataURLtoFile(dataURL, 'a.jpg');
        files.push(file)
        this.setState({ images, files })
        this.props.changeFiles(files, this.props.header.key, 'file')

        // this.setState({croppedImage: this.refs.cropper.getCroppedCanvas().toDataURL()})
        // var file = this.dataURLtoFile(dataURL, 'a.jpg');
        // this.setState({ file: file });
    }


    // dataURLtoFile(dataurl) {
    //     // var mime = 'image/jpeg';
    //     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);

    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     var ia = new Uint8Array(bstr.length);
    //     for (var i = 0; i < bstr.length; i++) {
    //         ia[i] = bstr.charCodeAt(i);
    //     }

    //     return new Blob([ia], { type: mime });
    // }


    removeOldFile(index) {
        var oldImages = this.state.oldImages
        oldImages.splice(index, 1)
        this.setState({ oldImages })
        this.props.changeValue(this.props.header.key, oldImages, this.props.extra)
    }



    removeFile(key, index) {
        var images = this.state.images
        var files = this.state.files
        images.splice(index, 1)
        files.splice(index, 1)
        this.setState({ images, files })
        this.props.changeFiles(files, this.props.header.key, 'file')

    }
}

export default ImageInput;
